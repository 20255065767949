import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon, ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { convertBankStatement, getRecentFiles } from '../services/api';

function Dashboard() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [excelUrl, setExcelUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recentFiles, setRecentFiles] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchRecentFiles();
  }, []);

  const fetchRecentFiles = async () => {
    try {
      const data = await getRecentFiles();
      setRecentFiles(data);
    } catch (error) {
      console.error('Error fetching recent files:', error);
      setError('Failed to fetch recent files. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError('');

    // Generate preview
    const reader = new FileReader();
    reader.onload = (e) => setPreview(e.target.result);
    reader.onerror = () => setError('Failed to read file. Please try again.');
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a file to convert.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const blob = await convertBankStatement(file);
      const url = window.URL.createObjectURL(blob);
      setExcelUrl(url);
      fetchRecentFiles();
    } catch (error) {
      console.error('Error converting file:', error);
      setError('File conversion failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <form onSubmit={handleSubmit} className="mt-5">
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">PDF up to 10MB</p>
            </div>
          </div>
          {preview && (
            <div className="mt-4">
              <img src={preview} alt="Preview" className="max-w-xs mx-auto" />
            </div>
          )}
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <button
            type="submit"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={isLoading}
          >
            {isLoading ? 'Converting...' : 'Convert to Excel'}
          </button>
        </form>
        {excelUrl && (
          <div className="mt-4">
            <a
              href={excelUrl}
              download="converted_statement.xlsx"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
              Download Excel
            </a>
          </div>
        )}
        <div className="mt-8">
          <h2 className="text-lg font-medium text-gray-900">Recent Files</h2>
          <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {recentFiles.map((file) => (
              <li key={file.id} className="col-span-1 flex shadow-sm rounded-md">
                <div className="flex-shrink-0 flex items-center justify-center w-16 bg-indigo-600 text-white text-sm font-medium rounded-l-md">
                  {file.name.substr(0, 2).toUpperCase()}
                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                  <div className="flex-1 px-4 py-2 text-sm truncate">
                    <a href={file.url} className="text-gray-900 font-medium hover:text-gray-600">
                      {file.name}
                    </a>
                    <p className="text-gray-500">{new Date(file.createdAt).toLocaleDateString()}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;