import axios from 'axios';

const API_URL = '/api';

export const convertBankStatement = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(`${API_URL}/convert`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });

    return response.data;
  } catch (error) {
    console.error('Error converting bank statement:', error);
    throw error;
  }
};

export const getRecentFiles = async () => {
  try {
    const response = await axios.get(`${API_URL}/recent-files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent files:', error);
    throw error;
  }
};