import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../services/AuthContext';

function Header() {
  const { user, logout } = useAuth();

  return (
    <header className="bg-gray-800 text-white">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="text-xl font-bold">Bank Statement Converter</Link>
          </div>
          <div className="flex items-center">
            <Link to="/" className="mx-2 hover:text-gray-300">Home</Link>
            <Link to="/about" className="mx-2 hover:text-gray-300">About</Link>
            {user ? (
              <>
                <Link to="/dashboard" className="mx-2 hover:text-gray-300">Dashboard</Link>
                <button onClick={logout} className="mx-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="mx-2 hover:text-gray-300">Login</Link>
                <Link to="/register" className="mx-2 hover:text-gray-300">Register</Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;